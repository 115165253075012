<template>
  <div id="contact" @click="defaultClick">
    <transition name="opaFadeIn">
    <loading v-if="isLoading" />
    </transition>
    <header>
      <top-title title="TELL US YOUR NEED" subTitle="告訴我們您的需求，讓我們為您打造全新品牌"></top-title>
    </header>
    <page-main-body
      title="尋找適合您的品牌計畫"
      subTitle="Find the right plan for your Brending"
      paragraph1="了解我們服務項目與案例後，也讓我們了解你現在可能遇到的問題，讓我們為你提供適當的解決方案，倘若您還不曉得自己目前需求，可依照以下內容找尋適合你的目標與預算。">
    </page-main-body>
    <div class="container">
      <div v-show="currentStep==1 || currentStep==2">
        <h2 style="margin-top: 4%">您從事的是那方面的產業呢？</h2>
        <h3 style="margin-bottom: 2%">What kind of industry is yours?</h3>
        <div style="position:relative;display:flex;justify-content:center">
          <button id="chooseIndustry" class="selector" @click.stop="focusIndust">
            <div class="holder">
              <i class="fas fa-caret-down"></i>  {{chosenIndust | indusHolder}}  <i class="fas fa-caret-down"></i>
            </div>
          </button>
          <div class="options">
            <div class="option" @click="chooseIndust">3C 配件</div>
            <div class="option" @click="chooseIndust">文創產業</div>
            <div class="option" @click="chooseIndust">行銷 / 廣告業</div>
            <div class="option" @click="chooseIndust">教育 / 顧問</div>
            <div class="option" @click="chooseIndust">房地產</div>
            <div class="option" @click="chooseIndust">家電 / 家飾</div>
            <div class="option" @click="chooseIndust">精品 / 珠寶 / 手錶</div>
            <div class="option" @click="chooseIndust">服裝</div>
            <div class="option" @click="chooseIndust">內衣 / 睡衣</div>
            <div class="option" @click="chooseIndust">包鞋配件</div>
            <div class="option" @click="chooseIndust">醫美 / 保養</div>
            <div class="option" @click="chooseIndust">彩妝 / 香水</div>
            <div class="option" @click="chooseIndust">美髮造型</div>
            <div class="option" @click="chooseIndust">食品 / 飲料 / 零食 / 生鮮</div>
            <div class="option" @click="chooseIndust">養生保健 / 醫療</div>
          </div>
        </div>
        <h2 style="margin-top: 4%">您了解您的品牌計劃嗎？</h2>
        <h3 style="margin-bottom: 2%">Do you know your brand plan?</h3>
        <div style="position:relative;display:flex;justify-content:center">
          <button id="chooseBrandPlan" class="selector" @click.stop="focusPlan">
            <div class="holder">
              <i class="fas fa-caret-down"></i>  {{chosenplan | planHolder}}  <i class="fas fa-caret-down"></i>
            </div>
          </button>
          <div class="options">
            <div class="option" @click.stop="choosePlan('A', $event)">我還不清楚我的品牌計劃</div>
            <div class="option" @click.stop="choosePlan('B', $event)">我了解我的品牌計劃</div>
          </div>
        </div>
        <div v-if="chosenIndust == '請選擇您的產業' || plan == ''" style="margin:50vh 0"></div>
      </div>
      <transition name="opaFadeIn">
      <div class="noPlan" v-if="plan == 'A' && chosenIndust!='請選擇您的產業'">
        <h2 style="margin-top: 4%">現在您的品牌正邁入哪一個目標呢?</h2>
        <h3 style="margin-bottom: 2%">What is your main purpose of the marketing strategy?</h3>
        <div class="range">
          <input type="range" min="1" max="5" name="purpose" v-model="purpose">
          <div class="labels">
            <span @click="choosePurpose(1)" :class="{'choosen': purpose == 1 }">初步規畫品牌</span>
            <span @click="choosePurpose(2)" :class="{'choosen': purpose == 2 }">口碑聲量與知名度</span>
            <span @click="choosePurpose(3)" :class="{'choosen': purpose == 3 }">豐富社群媒體與互動</span>
            <span @click="choosePurpose(4)" :class="{'choosen': purpose == 4 }">增加網站流量</span>
            <span @click="choosePurpose(5)" :class="{'choosen': purpose == 5 }">提高轉單 / 名單 / 下載數</span>
          </div>
        </div>
        <h2 style="margin-top: 8%">根據預算尋找項目計畫</h2>
        <h3 style="margin-bottom: 2%">Looking for a target plan by budget</h3>
        <div class="range">
          <input type="range" min="1" max="9" name="budget" v-model="budget">
          <div class="labels">
            <span @click="chooseBudget(1)" :class="{'choosen': budget == 1 }">30,000</span>
            <span @click="chooseBudget(2)" :class="{'choosen': budget == 2 }">50,000</span>
            <span @click="chooseBudget(3)" :class="{'choosen': budget == 3 }">80,000</span>
            <span @click="chooseBudget(4)" :class="{'choosen': budget == 4 }">100,000</span>
            <span @click="chooseBudget(5)" :class="{'choosen': budget == 5 }">150,000</span>
            <span @click="chooseBudget(6)" :class="{'choosen': budget == 6 }">200,000</span>
            <span @click="chooseBudget(7)" :class="{'choosen': budget == 7 }">250,000</span>
            <span @click="chooseBudget(8)" :class="{'choosen': budget == 8 }">300,000</span>
            <span @click="chooseBudget(9)" :class="{'choosen': budget == 9 }">400,000 UP</span>
          </div>
        </div>
        <div class="suggestion">
          <h3>綜合數據推薦方案</h3>
          <ol>
            <li v-for="(topic, idx) in serviceTopic" :class="{'sugessted': sugesstJudge[idx]}" :key="idx">
              <span>{{topic.name}}</span>
              <button>
                <i v-if="sugesstJudge[idx]" class="fas fa-check-circle"></i>
                <i v-else class="far fa-circle"></i>
              </button>
            </li>
          </ol>
        </div>
          <span>※ 以上建議時間範圍為 1 - 3 個月。Try it 僅是提共該計畫適合行銷解決方案並非代表該價格即是涵蓋 Try it 內容。</span>
          <button class="no-plan-try" @click="tryIt('A')">Try it !</button>
      </div>
      </transition>
      <transition name="opaFadeIn">
      <div class="gotPlan" v-if="plan == 'B' && chosenIndust!='請選擇您的產業'">
        <h2 style="margin-top: 4%">我有品牌規劃!只需要尋找我要的項目</h2>
        <h3 style="margin-bottom: 2%">I got plans! Just need to pick up catagories.</h3>
        <div class="pickup">
          <label :for="`topic${idx}`" v-for="(topic, idx) in serviceTopic" class="topic" :key="idx">
            {{idx + 1 | decimalLeadingZero}} {{topic.name}}
            <input hidden :id="`topic${idx}`" :name="`topic${idx}`" type="checkbox" @change="pick(idx, $event)"/>
          </label>
        </div>
        <h3 style="margin-top: 4%">您以選取了下方選項</h3>
        <transition name="opaFadeIn">
        <div style="width:100%;" v-if="picked.length > 0">
        <div class="pickedTopic">
          <span v-for="(topic, idx) in picked" :key="idx">
            {{topic.idx}} {{topic.name}}
          </span>
        </div>
        <button @click="tryIt('B')">Try it !</button>
        </div>
        </transition>
      </div>
      </transition>
      <transition name="opaFadeIn">
      <div class="info" v-show="currentStep==0 || currentStep==2">
        <form id="sendingInfo">
          <div class="input" @click="inputClick" v-show="currentStep==0">
            <label>姓名*:</label>
            <input @focus="inputFocus" @blur="inputVal('name', $event)" @keydown.enter.prevent="focusNext(1)"
            type="text" required name="name">
          </div>
          <div class="input" @click="inputClick" v-show="currentStep==0">
            <label>公司名稱*:</label>
            <input @focus="inputFocus" @blur="inputVal('companyName', $event)" @keydown.enter.prevent="focusNext(2)"
            type="text" required name="companyName">
          </div>
          <div class="input" @click="inputClick" v-show="currentStep==0">
            <label>信箱*:</label>
            <input @focus="inputFocus" @blur="inputVal('email', $event)" @keydown.enter.prevent="focusNext(3)"
            type="text" required name="email">
          </div>
          <div class="input" @click="inputClick" v-show="currentStep==0">
            <label>電話*:</label>
            <input @focus="inputFocus" @blur="inputVal('phone', $event)" @keydown.enter.prevent="focusNext(4)"
            type="text" required name="phone">
          </div>
          <div class="input" @click="inputClick" v-show="currentStep==0">
            <label>公司地區*:</label>
            <select @focus="inputFocus" @blur="inputVal('companyLocation', $event)" @keydown.enter.prevent="focusNext(5)"
            type="text" required name="companyLocation">
              <option value="" disabled selected>請選擇地區</option>
              <option value="基隆市">基隆市</option>
              <option value="台北市">台北市</option>
              <option value="新北市">新北市</option>
              <option value="桃園縣">桃園縣</option>
              <option value="新竹市">新竹市</option>
              <option value="新竹縣">新竹縣</option>
              <option value="苗栗縣">苗栗縣</option>
              <option value="台中市">台中市</option>
              <option value="彰化縣">彰化縣</option>
              <option value="南投縣">南投縣</option>
              <option value="雲林縣">雲林縣</option>
              <option value="嘉義市">嘉義市</option>
              <option value="嘉義縣">嘉義縣</option>
              <option value="台南市">台南市</option>
              <option value="高雄市">高雄市</option>
              <option value="屏東縣">屏東縣</option>
              <option value="台東縣">台東縣</option>
              <option value="花蓮縣">花蓮縣</option>
              <option value="宜蘭縣">宜蘭縣</option>
              <option value="澎湖縣">澎湖縣</option>
              <option value="金門縣">金門縣</option>
              <option value="連江縣">連江縣</option>
              <option value="其他地區">其他地區</option>
            </select>
            <i class="fas fa-caret-down"></i>
          </div>
          <div class="input" @click="inputClick" v-show="false">
            <label class="minify">產業類別:</label>
            <input @focus="inputFocus" @blur="inputVal('companyType', $event)" @keydown.enter.prevent="focusNext(6)" disabled
            type="text" name="companyType" v-model="chosenIndust">
          </div>
          <div class="input" @click="inputClick" v-show="currentStep!=0 && !sended">
            <label class="minify">推薦我的方案:</label>
            <textarea @focus="inputFocus" @blur="inputVal('suggested', $event)" @keydown="autoGrow" @click="autoGrow"
            name="suggested"></textarea>
          </div>
          <div class="input" @click="inputClick" v-show="currentStep!=0 && !sended">
            <label>其他備註:</label>
            <textarea @focus="inputFocus" @blur="inputVal('other', $event)" @keydown="autoGrow" @click="autoGrow"
            name="other"></textarea>
          </div>
          <div v-show="!sended">
            <button @click.prevent="next(true)" v-show="currentStep==0">NEXT</button>
            <button @click.prevent="sendForm(false)" v-show="currentStep!=0">SEND</button>
          </div>
          <div v-show="sended">
            <h1>感謝您的填寫，將會有專員與您聯絡</h1>
          </div>
        </form>
      </div>
      </transition>
    </div>
    <GmapMap v-show="isTry"
      :center="{lat:25.067700, lng:121.490055}"
      :zoom="20"
      style="width: 100%; height: 50vh"
    >
      <GmapMarker
      :position="{lat:25.067750, lng:121.490055}"
      :clickable="true"
      :draggable="true"
    />
    </GmapMap>
    <footer-view style="margin-top: 4%" :isContact="true"/>
  </div>
</template>

<script>
import loading from "@/components/loading.vue";
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "contact",
  components: {
    loading,
    topTitle,
    pageMainBody,
    footerView
  },
  data() {
    return {
      isLoading: false,
      chosenIndust: "請選擇您的產業",
      chosenplan: "請選擇您的答案",
      currentStep: 0,
      plan: "",
      purpose: 1,
      budget: 1,
      serviceTopic: [
        {order: 1, idx: "01", name: "網站製作"},
        {order: 2, idx: "02", name: "廣告投放"},
        {order: 3, idx: "03", name: "品牌設計"},
        {order: 4, idx: "04", name: "廣告素材"},
        {order: 5, idx: "05", name: "商業攝影"},
        {order: 6, idx: "06", name: "形象影片"},
        {order: 7, idx: "07", name: "廣告影片"},
        {order: 8, idx: "08", name: "自媒體經營"},
        {order: 9, idx: "09", name: "新聞稿"},
        {order: 10, idx: "10", name: "部落客文字"},
        {order: 11, idx: "11", name: "討論區文章"},
        {order: 12, idx: "12", name: "網紅（個人或社群媒體）影片或貼文"},
        {order: 13, idx: "13", name: "Fans Play 全通路互動模組"},
      ],
      picked: [],
      isTry: false,
      suggested: "",
      isPickLocation: false,
      sended: false
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize)
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$parent.currentPage = "contact";
    setTimeout(() => {
      this.$parent.isLoading = false;
    }, 1000);
  },
  methods: {
    defaultClick() {
      $(".selector").removeClass("focus");
      this.isPickLocation = false;
    },
    next(boolean) {
      if (boolean && !this.sendForm(boolean)) {
        return;
      }
      this.currentStep++;
    },
    handleResize() {
      if (this.plan == "A") {
        this.alignLabels();
      }
    },
    alignLabels() {
      $(".labels").each(function() {
        var containerWidth = $(this)[0].clientWidth;
        var children = $(this).find("span");
        var parts = children.length - 1;
        children.each(function(idx) {
          this.style.left = idx * ((containerWidth) / parts) - this.clientWidth * 0.5 + "px";
        })
      });
    },
    focus(e) {
      e.target.focus();
    },
    focusIndust(e) {
      document.getElementById("chooseIndustry").classList.add("focus");
      document.getElementById("chooseBrandPlan").classList.remove("focus");
    },
    focusPlan() {
      document.getElementById("chooseBrandPlan").classList.add("focus");
      document.getElementById("chooseIndustry").classList.remove("focus");
    },
    chooseIndust(e) {
      var vm = this;
      this.chosenIndust = e.target.innerText;
      document.getElementById("chooseIndustry").children[0].style.color =
        "#f12379";
      document.getElementById("chooseIndustry").blur();
      document.getElementById("chooseIndustry").classList.remove("focus");
      setTimeout(function() {
        vm.alignLabels();
      }, 20);
    },
    choosePlan(plan, e) {
      var vm = this;
      this.plan = plan;
      this.chosenplan = e.target.innerText;
      document.getElementById("chooseBrandPlan").children[0].style.color =
        "#f12379";
      document.getElementById("chooseBrandPlan").blur();
      document.getElementById("chooseBrandPlan").classList.remove("focus");
      setTimeout(function() {
        vm.alignLabels();
      }, 20);
    },
    choosePurpose(num) {
      this.purpose = num;
    },
    chooseBudget(num) {
      this.budget = num;
    },
    inputClick(e) {
      if (e.target.children[1]) {
        e.target.children[1].focus();
      }
    },
    autoGrow(e) {
      setTimeout(function() {
        e.target.style.cssText = 'height:auto; padding:0';
        e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
      }, 0)
    },
    pickLocation(e) {
      this.isPickLocation = false;
      document.getElementsByName("companyLocation")[0].value = e.target.innerText;
      document.getElementsByName("companyLocation")[0].disabled = false;
      var locationLabel = document.getElementsByName("companyLocation")[0].previousElementSibling.classList;
      locationLabel.add("minify");
      locationLabel.remove("invalid");
    },
    inputFocus(e) {
      e.target.previousElementSibling.classList.add("minify");
      if (e.target.name == "companyLocation") {
        this.isPickLocation = true;
      }
      // if (e.target.name == "suggested") {
      //   e.target.disabled = true;
      // }
    },
    inputVal(type, e) {
      var val = e.target.value;
      var flag = true;
      if (e.type == "blur") {
        val == "" ?
        e.target.previousElementSibling.classList.remove("minify"):
        e.target.previousElementSibling.classList.add("minify");
        e.target.disabled = false;
      }
      if (type == "name" || type == "companyName" || type == "companyLocation" || type == "phone") {
        if (val == "") {
          e.target.previousElementSibling.classList.add("invalid");
          flag = false;
        } else {
          e.target.previousElementSibling.classList.remove("invalid");
        }
      }
      if (type == "email") {
        if(this.inputJudgeEmail(val)) {
          document.getElementsByName("email")[0].previousElementSibling.classList.remove("invalid");
        } else {
          document.getElementsByName("email")[0].previousElementSibling.classList.add("invalid");
          flag = false;
        }
      }
      if (type == "phone") {
        if (this.inputJudgePhone(val)) {
          document.getElementsByName("phone")[0].previousElementSibling.classList.remove("invalid");
        } else {
          document.getElementsByName("phone")[0].previousElementSibling.classList.add("invalid");
          flag = false;
        }
      }
      return flag;
    },
    inputJudgeEmail(val) {
      var emailRule = /^[^\s]+@[^\s]+\.[^\s]{2,3}$/;
      return emailRule.test(val);
    },
    inputJudgePhone(val) {
      // var phoneRule = /^[-0-9]{10,15}$/;
      // return phoneRule.test(val);
      return true;
    },
    focusNext(idx) {
      idx == "6"
      ? document.getElementsByName("suggested")[0].focus()
      : $(".input input").not("[hidden]")[idx].focus();
    },
    tryIt() {
      var pickedTopic = new Array;
      if (this.plan == "A") {
        $("li.sugessted").each(function(data) {
          pickedTopic.push($(this).find("span")[0].innerText)
        });
      } else {
        this.picked.forEach(function(data) {
          pickedTopic.push(data.name);
        });
      }
      document.getElementsByName("suggested")[0].value = pickedTopic.join(" / ");
      this.currentStep = 2;
      setTimeout(function() {
        $("html, body").animate({ scrollTop: $("#sendingInfo").offset().top - 88}, 1200, "swing",
          function() {
            document.getElementsByName("suggested")[0].click();
          });
      }, 20);
    },
    sendForm(isNext) {
      var vm = this;
      vm.isLoading = true;
      var flag = true;
      $("#sendingInfo [required]").each(function(idx, el) {
        if (this.value == "") {
          this.previousElementSibling.classList.add("invalid");
          alert("必填欄位" + el.previousElementSibling.innerText + "不得為空");
          flag = false;
          return false;
        }
      });
      if (!flag) {
        vm.isLoading = false;
        return;
      }
      flag = this.inputJudgeEmail(document.getElementsByName("email")[0].value) && this.inputJudgePhone(document.getElementsByName("phone")[0].value);
      if (!flag) {
        vm.isLoading = false;
        alert("信箱或電話格式錯誤");
        return;
      } else if (isNext){
        vm.isLoading = false;
        return true;
      } else {
        var datas = new Object;
        $(".input").each(function() {
          if ( $(this).find("input").length > 0 ) {
            var key = $(this).find("input")[0].name;
            var value = $(this).find("input")[0].value;
            datas[key] = value;
          }
          if ( $(this).find("select").length > 0 ) {
            var key = $(this).find("select")[0].name;
            var value = $(this).find("select")[0].value;
            datas[key] = value;
          }
          if ( $(this).find("textarea").length > 0 ) {
            var key = $(this).find("textarea")[0].name;
            var value = $(this).find("textarea")[0].value;
            datas[key] = value;
          }
        });
        if (vm.plan == "A") {
          $(".range").each(function() {
            var key = $(this).find("input")[0].name;
            var value = $(this).find(".labels .choosen")[0].innerText;
            datas[key] = value;
          })
        }
        // console.log(datas)
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_PHP + "ClientAPI/contactInfo",
          data: datas,
          dataType: "json",
          success: function(res) {
            vm.isLoading = false;
            if (res.message == "success") {
              vm.sended = true;

              // 第三方數據
              fbq('track', 'tsaiyiOrder');
              fbq('track', 'CompleteRegistration');

              //google adwords
              gtag_report_conversion();

              //google  analytics
              goog_report_conversion();

              ga('send', 'event', {
                  eventCategory: 'fansplay_page',
                  eventAction: 'order'
              });

            } else {
              alert("資料異常!請新整理");
            }
          },
          error: function(res) {

            vm.isLoading = false;
            alert("資料異常!請新整理");
          }
        })
      }
    },
    setJudgeArray(income) {
      var vm = this;
      var judgeArray = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      this.budget > income.length
        ? judgeArray.forEach(function(data, idx) {
            judgeArray[idx] = true;
          })
        : income.forEach(function(data, idx) {
            if (vm.budget == idx + 1) {
              var prossecer = data.replace(/\s/g, "").split(",");
              prossecer.forEach(function(sets) {
                judgeArray[sets - 1] = true;
              });
            }
          });
      return judgeArray;
    },
    pick(idx, e) {
      if (e.target.checked) {
        e.target.parentElement.classList.add("picked");
        this.picked.push(this.serviceTopic[idx]);
        this.sortPicked();
      } else {
        var vm = this;
        e.target.parentElement.classList.remove("picked");
        vm.picked.forEach(function(topic, i) {
          if (topic.name == vm.serviceTopic[idx].name) {
            vm.picked.splice(i, 1);
          }
        });
        this.sortPicked();
      }
    },
    sortPicked() {
      this.picked.sort(function(a, b) {
        return a.order - b.order;
      })
    }
  },
  computed: {
    sugesstJudge() {
      var result;
      if (this.purpose == "1") {
        result = this.setJudgeArray([
          "3",
          "3, 5",
          "1, 3",
          "1, 3",
          "3, 5, 6",
          "1, 5, 6",
          "1, 3, 5, 6",
          "1, 2, 4, 12, 13",
          "1, 2, 4, 7, 9, 12, 13"
        ]);
      } else if (this.purpose == "2") {
        result = this.setJudgeArray([
          "10",
          "10, 13",
          "10, 11",
          "10, 11, 13",
          "9, 10, 13",
          "9, 10, 11",
          "8, 9, 10, 11",
          "8, 9, 10, 11, 12, 13",
          "2, 8, 9, 10, 11, 12, 13"
        ]);
      } else if (this.purpose == "3") {
        result = this.setJudgeArray([
          "13",
          "11",
          "8, 13",
          "8, 12, 13",
          "8, 11, 12, 13",
          "2, 8, 11, 12, 13",
          "2, 8, 12, 13",
          "2, 8, 11, 12, 13",
          "2, 8, 9, 10, 11, 12, 13"
        ]);
      } else if (this.purpose == "4") {
        result = this.setJudgeArray([
          "4, 13",
          "2, 4",
          "2, 4, 7",
          "2, 4, 7, 13",
          "2, 4, 7, 12, 13",
          "2, 4, 7, 12",
          "2, 4, 7",
          "2, 4, 7, 13",
          "2, 4, 7, 12, 13"
        ]);
      } else if (this.purpose == "5") {
        result = this.setJudgeArray([
          "4, 13",
          "2, 4, 13",
          "2, 7, 13",
          "2, 4, 7, 12, 13",
          "2, 4, 7, 8, 12, 13",
          "2, 4, 7, 8, 12, 13",
          "2, 4, 7, 8, 9, 12, 13",
          "2, 4, 7, 12, 13",
          "2, 4, 7, 12, 13"
        ]);
      }
      return result;
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../assets/scss/slider.css";
@import "@/assets/scss/style.scss";

header {
  background-image: url("../assets/image/contact/topBanner.jpg");
}

select {
  @include font(small);
  background-color: transparent;
  border: none;
  -webkit-appearance: button;
  width: 100%;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707070;
  .container {
    width: 80vw;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include font(small);
    }
    h3 {
      @include font(small);
    }
    .selector {
      position: relative;
      background-color: #fff;
      color: #707070;
      width: 100%;
      padding: 0 1%;
      border: none;
      outline: none;
      overflow: visible;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include font(small);
      &.focus ~ .options {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
      }
      .holder {
        width: 80vw;
        max-width: 1200px;
        color: #cccccc;
        border: 1px solid #707070;
        cursor: pointer;
      }
    }
    .options {
      position: absolute;
      top: 100%;
      background-color: #fff;
      z-index: 3;
      box-shadow: 0 0 4px 0 #707070;
      border-radius: 0 0 5px 5px;
      height: 0;
      padding: 0 2% 1% 2%;
      transform: scaleY(0);
      opacity: 0;
      transition: 0.5s;
      transform-origin: 50% 0;
      .option {
        margin: 2px 0;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          // padding: 10px 0;
          color: $mainRed;
        }
      }
    }
    .noPlan {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .range {
        width: 100%;
        margin: 4% 0;
        input {
          width: 100%;
        }
        .labels {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          font-weight: 700;
          @include font(smaller);
          @media #{$tablet} {
            flex-direction: column;
            align-items: center;
          }
          span {
            cursor: pointer;
            position: absolute;
            top: 100%;
            white-space: nowrap;
            @media #{$tablet} {
              left: auto !important;
              position: relative;
            }
            &.choosen {
              color: $mainRed;
            }
          }
        }
      }
      .suggestion {
        margin-top: 4%;
        width: 120%;
        max-width: 800px;
        border: 1px solid $mainRed;
        border-radius: 8px;
        overflow: hidden;
        h3 {
          background-color: $mainRed;
          color: #fff;
          font-weight: 300;
          padding: 10px 0;
        }
        ol {
          position: relative;
          padding-left: 10%;
          counter-reset: item;
          list-style-type: none;
          li {
            position: relative;
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            padding: 0 5%;
            text-align: left;
            @include font(small);
            transition: color 0.3s;
            &::before {
              position: absolute;
              right: 100%;
              @include font(small);
              top: 18%;
              content: counter(item, decimal-leading-zero) " ";
              counter-increment: item;
            }
            span {
              width: 70%;
            }
            button {
              // cursor: pointer;
              width: 10%;
              background-color: $mainBlue;
              padding: 1% 0;
              color: #fff;
              border: none;
              outline: none;
              @include font(small);
              transition: background-color 0.3s;
            }
            &.sugessted {
              font-weight: 700;
              color: $mainRed;
              button {
                font-weight: 700;
                background-color: $mainRed;
              }
            }
          }
        }
      }
      .no-plan-try {
        cursor: pointer;
        width: 30%;
        margin-top: 4%;
        background-color: $mainRed;
        padding: 1% 0;
        color: #fff;
        border: none;
        outline: none;
        @include font(small);
        transition: background-color 0.3s;
        margin-bottom: 2%;
      }
    }
    .gotPlan {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .pickup {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media #{$laptop} {
          justify-content: space-between;
        }
        @media #{$slim} {
          width: 95%;
        }
        .topic {
          cursor: pointer;
          @include font(small);
          margin: 1% 3%;
          border-radius: 12px;
          padding: 1% 2%;
          border-color: #fff;
          transition: border-color .3s;
          white-space: nowrap;
          @media #{$phone} {
            font-size: 14px;
          }
          &.picked {
            border: 1px solid $mainRed;
            padding: calc(1% - 1px) calc(2% - 1px);
          }
        }
      }
      .pickedTopic {
        @include font(small);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border: 1px solid #707070;
        margin: 2% 0;
        padding: 1%;
        @media #{$phone} {
          font-size: 11px;
        }
        span {
          cursor: default;
          display: flex;
          align-items: center;
          white-space: nowrap;
          margin-right: 4%;
          &:after {
            content: "/";
            display: block;
            margin-left: 20px;
          }
          &:nth-last-child(1)::after {
            display: none;
          }
        }
      }
      button {
        cursor: pointer;
        width: 30%;
        background-color: $mainRed;
        padding: 1% 0;
        color: #fff;
        border: none;
        outline: none;
        @include font(small);
        transition: background-color 0.3s;
        margin-bottom: 2%;
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 6%;
    @media #{$tablet} {
      flex-direction: column;
    }
    #sendingInfo {
      width: 60%;
      @media #{$tablet} {
        width: 100%;
      }
      .input {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        border-bottom: 1px solid #707070;
        @include font(small);
        label {
          white-space: nowrap;
          margin: 0 1%;
          transition: .3s;
          z-index: -1;
          &.minify {
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(.6) translate(-50%, -150%);
            color: $mainBlue;
          }
          &.invalid {
            color: $mainRed;
          }
        }
        input, textarea {
          @include font(small);
          color: #707070;
          background-color: transparent;
          width: 100%;
          height: 32px;
          padding: 3px 0;
          outline: none;
          border: none;
          overflow: hidden;
          margin-bottom: 3px;
          &:focus {
            transition: box-shadow .3s;
            box-shadow: 0px 0px 4px 2px $mainBlue;
          }
        }
        #pickLocation {
          position: absolute;
          width: 90%;
          top: -50%;
          display: flex;
          justify-content: space-between;
          box-shadow: 0 0 4px 0 #707070;
          border-radius: 5px;
          flex-wrap: wrap;
          background-color: #fff;
          z-index: 2;
          padding: 1%;
          span {
            width: 30%;
            cursor: pointer;
            margin: 1% 0;
            transition: .3s;
            &:hover {
              color: $mainPurple;
            }
          }
        }
      }
      button {
        @include font(small);
        cursor: pointer;
        width: 40%;
        padding: 1% 2%;
        background-color: #fff;
        color: #707070;
        outline: none;
        border: 1px solid #707070;
      }
    }
  }
}
</style>
