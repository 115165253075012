<template>
  <div class="pageMainBody">
    <div class="title">
        <h1>{{title}}</h1>
        <p>{{subTitle}}</p>
      </div>
      <hr v-if="title != '' && subTitle != ''"/>
      <div class="story">
        <p>
          {{paragraph1}}
        </p>
        <p>
          {{paragraph2}}
        </p>
        <p>
          {{paragraph3}}
        </p>
        <p>
          {{paragraph4}}
        </p>
        <p>
          {{paragraph5}}
        </p>
      </div>
  </div>
</template>

<script>
export default {
  name: "pageMainBody",
  props: {
    title: String,
    subTitle: String,
    paragraph1: String,
    paragraph2: String,
    paragraph3: String,
    paragraph4: String,
    paragraph5: String
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.pageMainBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 57px;
  @media #{$phone} {
    margin-top: 10px;
  }
  .title {
    width: 90%;
    h1 {
      margin: 0;
      @include font(medium);
    }
    p {
      margin: 0;
      @include font(medium);
    }
  }
  hr {
    background-color: #707070;
    height: 1px;
    max-width: 600px;
    width: 85%;
    margin: 2% 0;
    @media #{$phone} {
      margin-top: 20px;
    }
  }
  .story {
    max-width: 1200px;
    width: 90%;
    text-align: left;
    @include font(small);
    font-weight: 300;
    p {
      padding-bottom: 2%;
    }
  }
}
</style>
